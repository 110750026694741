@font-face {
    font-family: 'Bold';
    src: url("./assets/fonts/Grotesk/Indian\ Type\ Foundry\ -\ WeissenhofGrotesk-Bold.otf");
  }
  
  @font-face {
    font-family: 'Regular';
    src: url("./assets/fonts/Grotesk/Indian\ Type\ Foundry\ -\ WeissenhofGrotesk-Regular.otf");
  }
  
  @font-face {
    font-family: 'Medium';
    src: url("./assets/fonts/Grotesk/Indian\ Type\ Foundry\ -\ WeissenhofGrotesk-Medium.otf");
  }

body {
    background-color: #fff;
    background-size: cover;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-top: auto;
    position: absolute;
    margin: auto;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#__next {
    height: 100%;
}